<template>
  <span class="def-label m-l-xs font-11 b-radius-3px">
    <i class="fal m-l-xxs m-r-xxs fa-fw" :class="iconClass"></i>
    {{ canal || 'Email' }}
  </span>
</template>

<script>
export default {
  name: "CampanhaIcone",
  props: ['canal'],
  computed: {
    iconClass () {
      let css = []
      if (this.canal === 'email') {
        css.push('fa-envelope')
      }
      if (this.canal === 'sms') {
        css.push('fa-mobile')
      }
      if (this.canal === 'whatsapp') {
        css.push('fa-whatsapp')
      }
      if (this.canal === 'telefone') {
        css.push('fa-phone')
      }
      return css
    }
  }
}
</script>
