<template>
  <a class="text-md ">
    {{titulo }} <campanha-icone />
  </a>
</template>

<script>
import CampanhaIcone from "@/components/marketing/components/CampanhaIcone"
export default {
  name: "CampanhaTitulo",
  props: ['titulo'],
  components: {CampanhaIcone}
}
</script>
