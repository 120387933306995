export default {
    computed: {
        tiposSeguimento () {
            return [
                {
                    label: 'Favoritou bem(ns) de tipo de bem específico',
                    value: 'tipo-bem-favorito'
                },
                {
                    label: 'Favoritou um bem específico',
                    value: 'bem-favorito'
                },
                {
                    label: 'Efetuou lance em um bem específico',
                    value: 'bem-lance'
                },
                {
                    label: 'Efetuou lance em bem(ns) de um tipo de bem específico',
                    value: 'tipo-bem-lance'
                },
                {
                    label: 'Está com o cadastro pendente de envio de documentos',
                    value: 'arrematante-pendente'
                },
                {
                    label: 'Está com o cadastro aprovado',
                    value: 'arrematante-aprovado'
                },
                {
                    label: 'Enviou proposta de venda direta em um bem específico',
                    value: 'arrematante-propostas-venda-direta-bem'
                },
                {
                    label: 'Arrematantes que compraram em leilões',
                    value: 'arrematante-comprou'
                }
            ]
        }
    },
    methods: {
        getTipoLabel (t) {
            switch (t) {
                case 'todos':
                    return 'Todas as pessoas registradas'
                case 'lista':
                    return 'Para uma lista'
                case 'leilao':
                    return 'Para um leilão'
                case 'segmento':
                    return 'Para um ou mais segmentos'
            }
        }
    }
}
